import { isTwaApp } from './twaUtils'

export const redirectToKarnameh = (pathname?: string, searchParams?: URLSearchParams) => {
  window.location.href = generateKarnameUrl(pathname, searchParams)
}

export const generateKarnameUrl = (pathname?: string, searchParams?: URLSearchParams) => {
  const KARNAMEH_URL = isTwaApp() ? 'https://next.karnameh.com' : import.meta.env.VITE_KARNAMEH_URL
  const url = new URL(KARNAMEH_URL)
  if (pathname) url.pathname = pathname
  if (isTwaApp()) url.searchParams.append('app_referrer', 'twa')
  if (searchParams) {
    searchParams.forEach((value, name) => url.searchParams.append(name, value))
  }
  return url.href
}
