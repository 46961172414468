import { ViolationHistoryDto, ViolationHistoryParam } from '@src/api/car-violation/CarViolation.dto'
import { CAR_PROFILE } from '@src/api/endpoints'
import { buildApiMutationHook, buildApiQueryHook, buildApiQueryNoParamsHook } from '@src/utils/api'
import { providerAxios } from '@src/utils/base.api'
import { INSPECTION_REPORT } from '@src/utils/consts'
import { queryClient } from '@src/utils/queryClient'
import { baseUrlNormalizer } from '@src/utils/stringNormalizers'

import {
  CarImageDto,
  CarImageUploadParam,
  CarImageUploadResponse,
  CarPriceEstimationDetailDto,
  CarProfileCreationDTO,
  CarProfileCreationParam,
  CarProfileDto,
  CarProfileParam,
  ConsultantCallParams,
  ConsultantCallResponse,
  ContradictionParams,
  DecideAuthorshipParams,
  EditOwnerInfoParams,
  GetCarManualDTO,
  GetCarManualParams,
  GetLatestPeriodicServiceParams,
  GetMileageHistoryDTO,
  GetMileageHistoryParams,
  ImageActionsType,
  InspectionReportDTO,
  InsuranceDto,
  LastOngoingOrderParams,
  LastOngoingOrderResponse,
  LatestPeriodicServiceDTO,
  LatestPeriodicServiceParams,
  ListPeriodicServiceParams,
  MonthlyMileageParams,
  OwnerInfoDTO,
  OwnerInfoParams,
  PlateDto,
  PlateParam,
  PlateParams,
  PriceEstimatorDTO,
  PriceEstimatorParams,
  PriceHisotryParam,
  PriceHisotryResponse,
  RetrievePeriodicServiceParams,
  UpdateCarBodyStatusParams,
  UpdateCarMileageParams,
  UpdatePeriodicServiceParams,
  UpdateTechnicalDiagnosisParams,
} from './CarProfile.dto'

export const useGetMyCars = buildApiQueryNoParamsHook<CarProfileDto[]>('my-cars', () =>
  providerAxios.get(CAR_PROFILE),
)

export const useGetCar = buildApiQueryHook<CarProfileParam, CarProfileDto>(
  (param) => `my-car-${param.id}`,
  (param) => providerAxios.get(baseUrlNormalizer(CAR_PROFILE, `${param.id}`)),
)

export const useDeleteCarProfileMutation = buildApiMutationHook<CarProfileParam, void>(
  (params) => providerAxios.delete(baseUrlNormalizer(CAR_PROFILE, [params.id.toString()])),
  (options) => ({
    onSuccess: (response, params, args) => {
      queryClient.invalidateQueries('my-cars')
      queryClient.invalidateQueries(`my-car-${params.id}`)
      options?.onSuccess?.(response, params, args)
    },
  }),
)

export const useGetCarImages = buildApiQueryHook<CarProfileParam, CarImageDto[]>(
  (param) => `my-car-images-${param.id}`,
  (param) => providerAxios.get(baseUrlNormalizer(CAR_PROFILE, [`${param.id}`, 'images'])),
)
export const useDeleteCarImage = buildApiMutationHook<ImageActionsType, void>(
  (param) =>
    providerAxios.delete(baseUrlNormalizer(CAR_PROFILE, [param.carId, 'images', param.imageId])),
  (options) => ({
    ...options,
    onSuccess: (response, params, other) => {
      queryClient.invalidateQueries(`my-car-images-${params.carId}`)
      options?.onSuccess?.(response, params, other)
    },
  }),
)

export const useSetPrimaryImageMutation = buildApiMutationHook<ImageActionsType, void>(
  (params) =>
    providerAxios.put(baseUrlNormalizer(CAR_PROFILE, [params.carId, 'images', params.imageId]), {
      is_primary: params.is_primary,
    }),
  (options) => ({
    ...options,
    onSuccess: (response, params, other) => {
      queryClient.invalidateQueries(`my-car-images-${params.carId}`)
      options?.onSuccess?.(response, params, other)
    },
  }),
)
export const useUploadCarPhoto = buildApiMutationHook<CarImageUploadParam, CarImageUploadResponse>(
  (param) => {
    const data = new FormData()
    data.append('image', param.image)
    data.append('is_primary', String(Boolean(param.is_primary)))
    return providerAxios.post(
      baseUrlNormalizer(CAR_PROFILE, [param.carId.toString(), 'images']),
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: param.onUploadProgress,
      },
    )
  },
  (options) => ({
    ...options,
    onSuccess: (response, params, other) => {
      queryClient.invalidateQueries(`my-car-images-${params.carId}`)
      options?.onSuccess?.(response, params, other)
    },
  }),
)

export const useUpdateTechnicalDiagnosisMutation = buildApiMutationHook<
  UpdateTechnicalDiagnosisParams,
  void
>(
  (params) => {
    const data = new FormData()
    if (params.image) {
      data.append('image', params.image)
    }
    if (params.expiration_date) {
      data.append('expiration_date', params.expiration_date)
    }
    return providerAxios.post(
      baseUrlNormalizer(CAR_PROFILE, [params.id, 'technical-diagnosis']),
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: params.onUploadProgress,
      },
    )
  },
  (options) => ({
    ...options,
    onSuccess: (...args) => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: 'my-cars' })
      }, 200)
      options?.onSuccess?.(...args)
    },
  }),
)

export const useSubmitNewCarProfile = buildApiMutationHook<
  CarProfileCreationParam,
  CarProfileCreationDTO
>(
  (param) => providerAxios.post(CAR_PROFILE, param),
  (options) => ({
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: 'my-cars' })
      options?.onSuccess?.(...args)
    },
  }),
)

export const useGetCarOwnerInfo = buildApiQueryHook<OwnerInfoParams, OwnerInfoDTO>(
  (params) => `car-${params.carId}-owner-info`,
  (params) => providerAxios.get(baseUrlNormalizer(CAR_PROFILE, [params.carId.toString(), 'owner'])),
)

export const useUpdatedOwnerInfoMutation = buildApiMutationHook<EditOwnerInfoParams>(
  (params) => {
    return providerAxios.post(baseUrlNormalizer(CAR_PROFILE, [params.carId.toString(), 'owner']), {
      name: params.name,
      national_id: params.national_id,
      phone_number: params.phone_number,
    })
  },
  (options) => ({
    ...options,
    onSuccess: (data, variables, context) => {
      setTimeout(() => {
        queryClient.invalidateQueries(`car-${variables.carId}-owner-info`)
      }, 200)
      options?.onSuccess?.(data, variables, context)
    },
  }),
)

export const useUpdateCarDetailMutation = buildApiMutationHook<Partial<CarProfileDto>, void>(
  (params) => providerAxios.patch(baseUrlNormalizer(CAR_PROFILE, [params.id ?? '0']), params),
  (options) => ({
    ...options,
    onSuccess: (data, variables, context) => {
      setTimeout(() => {
        queryClient.invalidateQueries(`my-car-${variables.id}`)
      }, 200)
      options?.onSuccess?.(data, variables, context)
    },
  }),
)

export const useUpdateCarMileageMutation = buildApiMutationHook<UpdateCarMileageParams>(
  (params) => {
    return providerAxios.post(baseUrlNormalizer(CAR_PROFILE, [params.id, 'statuses', 'mileage']), {
      mileage: params.mileage,
    })
  },
)

export const useGetCarMileageHistory = buildApiQueryHook<
  GetMileageHistoryParams,
  GetMileageHistoryDTO[]
>(
  (param) => `mileage-history-${param.id}`,
  (param) => providerAxios.get(`mileage/?vehicle=${param.id}`),
)

export const useUpdateCarBodyStatusMutation = buildApiMutationHook<UpdateCarBodyStatusParams>(
  (params) => {
    return providerAxios.post(baseUrlNormalizer(CAR_PROFILE, [params.id, 'statuses', 'body']), {
      summarized_status: params.summarized_status,
    })
  },
)

export const useEstimatePriceMutation = buildApiMutationHook<
  PriceEstimatorParams,
  PriceEstimatorDTO
>((params) => providerAxios.post(baseUrlNormalizer(CAR_PROFILE, [params.id, 'estimate'])))

export const useEstimatePriceQuery = buildApiQueryHook<PriceEstimatorParams, PriceEstimatorDTO>(
  (params) => [baseUrlNormalizer(CAR_PROFILE, [params.id, 'estimate'])],
  (params) => providerAxios.post(baseUrlNormalizer(CAR_PROFILE, [params.id, 'estimate'])),
)

export const useCarInspectionReport = buildApiQueryHook<CarProfileParam, InspectionReportDTO>(
  (params) => `inspection-report-${params.id}`,
  // (params) => providerAxios.get(baseUrlNormalizer(CAR_PROFILE, params.id.toString())),
  (params) => providerAxios.get(baseUrlNormalizer(INSPECTION_REPORT, params.id.toString())),
)

export const useUpdatePlateMutation = buildApiMutationHook<PlateParams, void>((params) =>
  providerAxios.post(baseUrlNormalizer(CAR_PROFILE, [params.id, 'plate']), params),
)

export const useUpdateMonthlyMileageMutation = buildApiMutationHook<MonthlyMileageParams, void>(
  ({ carId, ...params }) =>
    providerAxios.post(
      baseUrlNormalizer(CAR_PROFILE, [carId, 'monthly-mileage-self-expression']),
      params,
    ),
  (options) => ({
    ...options,
    onSuccess: (...args) => {
      setTimeout(() => {
        queryClient.invalidateQueries('my-cars')
      }, 200)
      options?.onSuccess?.(...args)
    },
  }),
)

export const useDecideAuthorshipMutation = buildApiMutationHook<DecideAuthorshipParams>(
  (params) =>
    providerAxios.post(baseUrlNormalizer(CAR_PROFILE, [params.id, 'decide-authorship']), {
      accept: params.accept,
    }),
  (options) => ({
    ...options,
    onSuccess: (...args) => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: 'my-cars' })
      }, 200)
      options?.onSuccess?.(...args)
    },
  }),
)

export const useCreateInsuranceMutation = buildApiMutationHook<
  Omit<InsuranceDto, 'id'> & { carId: string },
  void
>(({ carId, ...params }) =>
  providerAxios.post(baseUrlNormalizer(CAR_PROFILE, [carId, 'insurances']), params),
)

export const useUpdateInsuranceMutation = buildApiMutationHook<
  InsuranceDto & { carId: string },
  void
>(({ id, carId, ...params }) =>
  providerAxios.put(baseUrlNormalizer(CAR_PROFILE, [carId, 'insurances', id]), params),
)

export const useSubmitContradiction = buildApiMutationHook<ContradictionParams>(
  ({ carId, description }) =>
    providerAxios.post(baseUrlNormalizer(CAR_PROFILE, [carId, 'complaint']), { description }),
)

export const useSubmitConsultantRequest = buildApiMutationHook<
  ConsultantCallParams,
  ConsultantCallResponse
>((params) =>
  providerAxios.post(baseUrlNormalizer('', ['consultant-call-request', 'call-request']), {
    vehicle: params.vehicle,
  }),
)

export const useSubmitLatestPeriodicService = buildApiMutationHook<
  LatestPeriodicServiceParams,
  LatestPeriodicServiceDTO
>((params) => {
  return providerAxios.post(`car-profiles/${params.car_profile}/periodic-services/`, params)
})

export const useUpdatePeriodicService = buildApiMutationHook<
  UpdatePeriodicServiceParams,
  LatestPeriodicServiceDTO
>((params) => {
  return providerAxios.patch(
    `car-profiles/${params.car_profile}/periodic-services/${params.id}/`,
    params,
  )
})

export const useLatestPeriodicService = buildApiQueryHook<
  GetLatestPeriodicServiceParams,
  LatestPeriodicServiceDTO
>(
  (params) => `car-${params.id}-periodic-service`,
  (params) => {
    return providerAxios.get(`car-profiles/${params.id}/periodic-services/latest/`)
  },
)

export const useListPeriodicServices = buildApiQueryHook<
  ListPeriodicServiceParams,
  LatestPeriodicServiceDTO[]
>(
  (params) => `car-${params.car_profile}-periodic-services`,
  (params) => {
    return providerAxios.get(`car-profiles/${params.car_profile}/periodic-services/`)
  },
)

export const useRetrievePeriodicServices = buildApiQueryHook<
  RetrievePeriodicServiceParams,
  LatestPeriodicServiceDTO
>(
  (params) => `car-${params.id}-periodic-service`,
  (params) => {
    return providerAxios.get(`car-profiles/${params.car_profile}/periodic-services/${params.id}/`)
  },
)

export const useGetCarManual = buildApiQueryHook<GetCarManualParams, GetCarManualDTO[]>(
  (params) => `get-car-manual-${params.brand_model_en}`,
  (params) =>
    providerAxios.get('/reminder/car-manuals/', {
      params: { brand_model_en: params.brand_model_en },
    }),
)

export const useGetLastOngoingOrder = buildApiQueryHook<
  LastOngoingOrderParams,
  LastOngoingOrderResponse
>(
  (params) => `car-profile-${params.carProfileId}-order`,
  (params) => {
    return providerAxios.get(`/car-profiles/${params.carProfileId}/last-service/`)
  },
)

export const usePriceEstimation = buildApiQueryHook<CarProfileParam, CarPriceEstimationDetailDto>(
  (param) => [`car-profiles/${param.id}/detailed-estimate/`, param],
  (param) => providerAxios.get(`car-profiles/${param.id}/detailed-estimate/`),
)

export const usePriceHistory = buildApiQueryHook<PriceHisotryParam, PriceHisotryResponse>(
  (params) => [`car-profiles/${params.id}/price-history/`, params],
  (params) => providerAxios.get(`car-profiles/${params.id}/price-history/`, { params }),
)

export const useGetPLate = buildApiQueryHook<PlateParam, PlateDto>(
  (param) => `plate-${param.id}`,
  (param) => providerAxios.get(`/plate/${param.id}/`),
)
